<template>
  <div class="timeline">
    <div
      v-for="(notifica, index) in formattedNotifiche"
      :key="index"
      class="box"
      :class="classPositionOnTimeline(notifica.Src)"
    >
      <div class="section d-none text-center">
        <p>
          {{ notifica.descrizione }}
        </p>
      </div>
      <div class="content">
        <h2>{{ notifica.Ts }}</h2>
        <p>
          {{ $t("notifica.descrizione." + notifica.descrizione) }}
        </p>
        <img :src="getNotificaImageUrl(notifica.descrizione)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      formattedNotifiche: {},
    };
  },
  props: ["notifiche", "team1", "team2", "disciplina"],
  watch: {
    notifiche: function (val) {
      this.formatNotifiche(val);
    },
  },
  mounted() {
    this.$i18n.locale = localStorage.language;
    this.formatNotifiche(this.notifiche);
  },
  methods: {
    formatNotifiche(notifiche) {
      var formatted = [];
      var timeCounter = 1;
      var timeDelimiter = this.disciplina.includes("NBA") ? "quarto" : "tempo";

      this._.forEach(notifiche, function (notifica) {
        switch (notifica.descrizione) {
          case "Period End":
            break;
          case "Period Start":
            notifica.descrizione = timeCounter + "° " + timeDelimiter;
            formatted.push(notifica);
            timeCounter++;
            break;
          default:
            formatted.push(notifica);
            break;
        }
      });
      this.formattedNotifiche = formatted;
    },
    classPositionOnTimeline(team) {
      if (team == this.team1) return "left";
      else if (team == this.team2) return "right";
      else return "center";
    },
    getNotificaImageUrl(notifica) {
      var images = require.context("@/assets/notifiche/", false, /\.png$/);
      let flagImage = "";
      try {
        flagImage = images(`./${notifica}.png`);
      } catch (error) {
        flagImage = images(`./Goals.png`);
      }
      return flagImage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";
img{
  width: 30px;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 5px 0;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background: $accent;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.box {
  padding: 5px;
  position: relative;
  background: inherit;
  width: 50%;
  .section {
    background: whitesmoke;
    z-index: 2;
    p {
      margin: 0;
      line-height: 22px;
    }
  }

  .content {
    padding: 10px;
    background: whitesmoke;
    position: relative;

    h2 {
      margin: 0 0 10px 0;
      font-size: 14px;
      font-weight: normal;
      color: $accent;
    }
    p {
      margin: 0;
      line-height: 22px;
      color: #000000;
    }
  }

  &.center {
    left: 25%;
    z-index: 2;

    .section {
      display: block !important;
      background-color: $accent;
      color: $primary;
    }

    .content {
      display: none;
    }
  }

  &.left {
    left: 0;
  }

  &.right {
    left: 50%;
  }
}
.box.right::after,
.box.left::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid $accent;
  border-radius: 16px;
  z-index: 1;
}

.box.right::after {
  left: -8px;
}

.box.right::before,
.box.left::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: $accent;
  z-index: 1;
}

.box.right::before {
  left: 8px;
}

.box .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #f6d155;
  border: 2px solid $accent;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: $accent;
  z-index: 1;
}

.box.left {
  text-align: left;
  .content {
    padding-right: 90px;
  }
  .icon {
    right: 20px;
  }
}

.box.right {
  text-align: right;

  .content {
    padding-left: 90px;
  }
  .icon {
    left: 20px;
  }
}
</style>