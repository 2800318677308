<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton-table
        :rows="5"
        :columns="1"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table>
    </template>

    <div>
      <b-list-group class="mb-2">
        <b-list-group-item
          v-b-toggle="'collapse-menu-' + subject"
          class="list-group-header"
        >
          <b-row>
            <b-col> {{ subject }} </b-col>

            <b-col cols="1">
              <b-icon stacked icon="chevron-down"></b-icon>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-collapse
          :id="'collapse-menu-' + subject"
          :visible="windowWidth > 760"
        >
          <b-list-group-item @click="select(null)" :class="classSelected(null)">
            <b-row>
              <b-col> {{ title }}</b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item
            v-for="item in items"
            :key="item"
            @click="select(item)"
            :class="classSelected(item)"
          >
            <b-row>
              <b-col> {{ item }}</b-col>
              <b-col>
                <img
                  :class="subject"
                  style="float: right"
                  :src="getItemImage(item)"
                />
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-collapse>
      </b-list-group>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  data: () => {
    return {
      windowWidth: window.innerWidth,
      loadingTime: 0,
      maxLoadingTime: 3,
    };
  },
  props: ["loading", "title", "items", "selectedItem", "subject"],
  created() {
    this.$_loadingTimeInterval = null;
  },
  mounted() {},
  watch: {
    loading(newValue, oldValue) {
      if (newValue == true) this.loadingTime = 0;

      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    select(idItem) {
      this.$emit("select", idItem);
    },
    classSelected(idItem) {
      if (idItem == this.selectedItem) return "active";
      return "";
    },
    getItemImage(item) {
      switch (this.subject) {
        case "nazioni":
          return this.getMenuFlagImageUrl(item);
        case "discipline":
          return this.getMenuSportImageUrl(item);
      }
    },
    getMenuFlagImageUrl(nazione) {
      var images = require.context("@/assets/bandiere/", false, /\.png$/);
      let flagImage = "";
      try {
        flagImage = images(`./${nazione}.png`);
      } catch (error) {
        flagImage = images(`./Earth.png`);
      }
      return flagImage;
    },
    getMenuSportImageUrl(disciplina) {
      var images = require.context("@/assets/discipline/", false, /\.png$/);
      switch (disciplina) {
        case "NBA21":
          disciplina = "basket";
          break;
        case "PES21":
        case "FIFA21":
        default:
          disciplina = "calcio";
          break;
      }
      let flagImage = "";
      try {
        flagImage = images(`./${disciplina}.png`);
      } catch (error) {
        flagImage = images(`./calcio.png`);
      }
      return flagImage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.list-group {
  border-radius: 0 !important;
  border: 4px solid gray;
}
.list-group-header {
  background-color: $accent !important;
  border-color: $accent !important;
  border-radius: 0 !important;
  padding: 0.8rem 1.25rem !important;
  text-transform: uppercase;
  font-weight: bold;
}
img {
  &.nazioni {
    height: 20px;
    border-radius: 25%;
    //border: 0.5px solid $primary-lighter;
  }
  &.discipline {
    height: 20px;
  }
}

.active {
  font-weight: bold;
}
</style>