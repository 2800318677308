import Vue from "vue";
import VueRouter from "vue-router";

import Default from "@/layouts/Default.vue";
import FullContent from "@/layouts/FullContent.vue";

import Manifestazioni from "../components/Manifestazioni.vue";
import Evento from "../components/manifestazioni/Evento.vue";

import Regolamenti from "../components/Regolamenti.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Manifestazioni,
    meta: { layout: Default },
  },
  {
    path: "/manifestazione/:idManifestazione/evento/:idEvento",
    name: "evento",
    component: Evento,
    meta: { layout: FullContent },
  },
  {
    path: "/regolamenti",
    name: "regolamenti",
    component: Regolamenti,
    meta: { layout: Default },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
