import ajaxCall from "./ajaxCall";

//let endpoint = "http://betinvest.ejoy.local/api/webinterface/";
//let endpoint = "https://stagingproclub.ejoyesports.com/api/webinterface/";
let DEVELOPMENT = process.env.NODE_ENV === "development";
let endpoint = DEVELOPMENT ? "http://ejoybetinvest.test/api/webinterface/" : "https://stagingproclub.ejoyesports.com/api/webinterface/";
export default {
  getManifestazioni(giorno, cb) {
    ajaxCall
      .sendGet(endpoint + "manifestazioni", {giorno: giorno})
      .then((response) => cb(response.data));
  },

  getEventi(idManifestazione, giorno, cb) {
    if (idManifestazione == null) idManifestazione = 0;

    ajaxCall
      .sendGet(endpoint + "avvenimenti", { manifestazione: idManifestazione, giorno: giorno })
      .then((response) => cb(response.data));
  },

  getEvento(idManifestazione, idEvento, cb) {
    ajaxCall
      .sendGet(endpoint + "avvenimento_detail", {
        manifestazione: idManifestazione,
        evento: idEvento,
      })
      .then((response) => cb(response.data));
  },

  getClassifiche(giorno, cb) {
    ajaxCall
      .sendGet(endpoint + "classifiche", { data: giorno })
      .then((response) => cb(response.data));
  }
};
