var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"eventiComponent"}},[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-skeleton')],1),_c('b-col',[_c('b-skeleton')],1),_c('b-col',[_c('b-skeleton')],1),_c('b-col',[_c('b-skeleton')],1),_c('b-col',[_c('b-skeleton')],1)],1),_c('b-skeleton-table',{attrs:{"hide-header":"","rows":5,"columns":1,"table-props":{ bordered: true, striped: false }}})]},proxy:true}])},[(!_vm._.isEmpty(_vm.manifestazioni))?_c('div',[_c('b-nav',{attrs:{"tabs":"","fill":""}},_vm._l((_vm.stati),function(stato){return _c('b-nav-item',{key:stato,class:[
            stato,
            _vm.classSelectedStato(stato),
            _vm.isMobile ? 'w-50' : 'w-25' ],on:{"click":function($event){return _vm.selectStato(stato)}}},[_vm._v(" "+_vm._s(_vm.$t("evento.stato." + stato))+" ")])}),1),_vm._l((_vm.manifestazioni),function(manifestazione){return _c('div',{key:manifestazione.ManifestazioneID},[(_vm.showManifestazione(manifestazione.ManifestazioneID))?_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manifestazione-' + manifestazione.ManifestazioneID),expression:"'manifestazione-' + manifestazione.ManifestazioneID"}],staticClass:"header-manifestazione",class:_vm.statusManifestazione(
              _vm.mappedEventi[manifestazione.ManifestazioneID]
            )},[_c('b-row',{class:_vm.isMobile ? 'no-gutters' : ''},[_c('b-col',{attrs:{"cols":"2","md":"1"}},[_vm._v(" "+_vm._s(manifestazione.DisciplineName)+" ")]),_c('b-col',{class:_vm.isMobile ? 'justify-content-center' : '',attrs:{"cols":"8","md":"1"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.mappedEventi[manifestazione.ManifestazioneID][0].StartDT,"H:mm"))+" ")]),_c('b-col',{staticClass:"d-block d-md-none text-center",attrs:{"cols":"2"}},[_c('img',{staticClass:"flag",staticStyle:{"float":"right"},attrs:{"src":_vm.getFlagImageUrl(manifestazione.CategoryName)}})]),_c('b-col',{attrs:{"cols":"10","md":"8"}},[_c('b',[_vm._v(_vm._s(manifestazione.TournamentName))])]),_c('b-col',{staticClass:"d-none d-md-block",attrs:{"cols":"3","md":"1"}},[_c('img',{staticClass:"flag",staticStyle:{"float":"right"},attrs:{"src":_vm.getFlagImageUrl(manifestazione.CategoryName)}})]),_c('b-col',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                'ranking-manifestazione-' + manifestazione.ManifestazioneID
              ),expression:"\n                'ranking-manifestazione-' + manifestazione.ManifestazioneID\n              "}],staticClass:"ranking",attrs:{"cols":"2","md":"1"},on:{"click":function($event){$event.stopPropagation();}}},[(
                  _vm.statusManifestazione(
                    _vm.mappedEventi[manifestazione.ManifestazioneID]
                  ) == 'finished'
                )?_c('img',{attrs:{"src":require("@/assets/ranking.png")}}):(
                  _vm.statusManifestazione(
                    _vm.mappedEventi[manifestazione.ManifestazioneID]
                  ) == 'live'
                )?_c('img',{attrs:{"src":require("@/assets/rankinprogress.png")}}):_vm._e()])],1)],1):_vm._e(),(
            _vm.showManifestazione(manifestazione.ManifestazioneID) &&
            (_vm.statusManifestazione(
              _vm.mappedEventi[manifestazione.ManifestazioneID]
            ) == 'finished' ||
              _vm.statusManifestazione(
                _vm.mappedEventi[manifestazione.ManifestazioneID]
              ) == 'live') &&
            _vm.classifiche[manifestazione.ManifestazioneID]
          )?_c('b-collapse',{staticClass:"ranking",attrs:{"id":'ranking-manifestazione-' + manifestazione.ManifestazioneID},model:{value:(
            _vm.collapses[
              'ranking-manifestazione-' + manifestazione.ManifestazioneID
            ]
          ),callback:function ($$v) {_vm.$set(_vm.collapses, 
              'ranking-manifestazione-' + manifestazione.ManifestazioneID
            , $$v)},expression:"\n            collapses[\n              'ranking-manifestazione-' + manifestazione.ManifestazioneID\n            ]\n          "}},[_c('b-table',{staticClass:"m-0",attrs:{"responsive":"","large":"","striped":"","items":_vm.classifiche[manifestazione.ManifestazioneID],"fields":_vm.campiTabellaClassifica}})],1):_vm._e(),(_vm.showManifestazione(manifestazione.ManifestazioneID))?_c('b-collapse',{attrs:{"id":'manifestazione-' + manifestazione.ManifestazioneID,"visible":_vm.toggleVisibilityCollapseManifestazione(
              manifestazione.ManifestazioneID
            )},on:{"show":function($event){return _vm.triggerBtnRanking(manifestazione.ManifestazioneID)}}},_vm._l((_vm.mappedEventi[manifestazione.ManifestazioneID]),function(evento){return _c('b-list-group-item',{key:evento.EventID,on:{"click":function($event){return _vm.openEvento(evento)}}},[_c('b-row',{staticClass:"no-gutters w-100"},[_c('b-col',{class:_vm.isMobile ? 'text-center' : '',attrs:{"cols":"4","md":"1"}},[_vm._v(" "+_vm._s(_vm._f("moment")(evento.StartDT,"H:mm"))+" ")]),_c('b-col',{staticClass:"d-block d-md-none",attrs:{"cols":"4"}}),_c('b-col',{staticClass:"text-center stato-evento d-block d-md-none",class:evento.status,attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("evento.stato." + evento.status))+" ")]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"5","md":"4"}},[_vm._v(" "+_vm._s(evento.team1)+" "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v(" ("),_c('b',[_vm._v(_vm._s(evento.nick1))]),_vm._v(") ")]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('b',[_vm._v(" "+_vm._s(evento.total_score_1)+" - "+_vm._s(evento.total_score_2)+" ")])]),_c('b-col',{staticClass:"text-left",attrs:{"cols":"5","md":"4"}},[_vm._v(" "+_vm._s(evento.team2)+" "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v(" ("),_c('b',[_vm._v(_vm._s(evento.nick2))]),_vm._v(") ")]),_c('b-col',{staticClass:"text-center stato-evento d-none d-md-block",class:evento.status,attrs:{"cols":"12","md":"1"}},[_vm._v(" "+_vm._s(_vm.$t("evento.stato." + evento.status))+" ")]),_c('b-col',{staticClass:"d-block d-md-none text-center",staticStyle:{"text-decoration":"underline"},attrs:{"cols":"12"}},[(evento.status == 'InPlay')?_c('span',[_vm._v("Segui match")]):(evento.status == 'Finished')?_c('span',[_vm._v("Dettagli match")]):_vm._e()])],1)],1)}),1):_vm._e()],1)})],2):_c('div',[_vm._v(" "+_vm._s(_vm.$tc("evento.contatore", _vm.manifestazioni))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }