<template>
  <div v-if="evento && evento.info">
    <div class="intestazione">
      <b-row class="w-100">
        <b-col cols="5">
          {{ evento.info.startdate | moment("H:mm") }}
        </b-col>
        <b-col cols="2">
          <img src="@/assets/ranking.png" style="height: 20px" />
        </b-col>
        <b-col cols="5">
          {{ $t("evento.stato." + evento.info.status) }}
        </b-col>
      </b-row>
    </div>
    <div class="video" v-if="evento.info.streaming">
      <b-row class="w-100 header">
        <b-col> VIDEO MATCH</b-col>
      </b-row>
      <iframe
        v-if="checkStreamingTwitch(evento.info.streaming)"
        :src="getStreamingTwitchUrl(evento.info.streaming)"
        class="w-100"
        style="height: 40vh"
        frameBorder="0"
      ></iframe>
       <!--<video-embed
        v-else
        :params="setYoutubeParams(evento.info.streaming)"
        :src="evento.info.streaming"
      ></video-embed>-->
      <b-link v-else :href="evento.info.streaming" target="_blank">
        <div class="w-100 youtube-button">
          <span>{{ $t("youtube.watch") }}</span>
          <img src="@/assets/youtube.png" alt="Youtube" />
        </div>
      </b-link>
    </div>
    <div class="info">
      <b-row class="w-100 header">
        <b-col cols="12" class="text-center"> INFO </b-col>
      </b-row>
      <b-row class="w-100 body">
        <b-col cols="5" class="text-center">
          {{ evento.info.team1 }}
          <br />
          ({{ evento.info.nick1 }})
        </b-col>
        <b-col cols="2" class="text-center"> </b-col>
        <b-col cols="5" class="text-center">
          {{ evento.info.team2 }}
          <br />
          ({{ evento.info.nick2 }})
        </b-col>
      </b-row>
      <b-row class="w-100 body">
        <b-col cols="5" class="text-center">
          <b>
            {{ evento.info.score1 }}
          </b>
        </b-col>
        <b-col cols="2" class="text-center">
          <b> - </b>
        </b-col>
        <b-col cols="5" class="text-center">
          <b>
            {{ evento.info.score2 }}
          </b>
        </b-col>
      </b-row>
    </div>
    <b-tabs
      class="mt-5"
      content-class="py-4"
      active-nav-item-class="font-weight-bold"
    >
      <b-tab title="Informazioni partita" v-if="evento.notifiche">
        <NotificheTimeline 
        :notifiche="evento.notifiche"
        :team1="evento.info.team1"
        :team2="evento.info.team2"
        :disciplina="evento.info.discipline"
        > </NotificheTimeline>
       
      </b-tab>
      <b-tab title="Classifica" v-if="evento.classifica"> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import api from "../../services/api";
import queryString from "query-string";
import NotificheTimeline from "./NotificheTimeline.vue";

export default {
  data: () => {
    return {
      interval: null,
      evento: {},
    };
  },
  components: {
    NotificheTimeline,
  },
  mounted() {
    this.getEvento();
    this.interval = setInterval(() => this.getEvento(), 5 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  computed: {
    hostname: () => window.location.hostname,
    /*youtubeParams: () => {
      if(typeof this.evento === 'undefined') return {};
      return this.setYoutubeParams(this.evento.info.streaming);
    }*/
  },
  methods: {

    setYoutubeParams(link){
      console.log(link);
      let search = link.split('?')[1];
      let obj = {};
      if(!this._.isUndefined(search)){
       obj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
      }
      obj.autoplay =1;
      obj.origin = window.location.hostname;
      return obj;
    },

    getEvento() {
      api.getEvento(
        this.$route.params.idManifestazione,
        this.$route.params.idEvento,
        (data) => {
          this.evento = data;
        }
      );
    },
    checkStreamingTwitch(url) {
      return url.includes("twitch");
    },
    getStreamingTwitchUrl(url) {
      var parsed = url;
      if (url.indexOf("?") !== -1)
        parsed = queryString.parse(url.split("?")[1]);
      var channel = parsed.channel;
      if (this._.isUndefined(channel)) {
        var split = url.split("/");
        channel = split[3];
      }
      return (
        "https://player.twitch.tv/?channel=" +
        channel +
        "&enableExtensions=false&muted=false&parent=" +
        this.hostname +
        "&volume=1"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.youtube-button {
  height: 40vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #181818;
  flex-direction: column;
  span {
    text-decoration: none;
    color: #fdf8f8;
  }
  img {
    width: 80px;
    height: 80px;
  }
}

.row {
  margin: 0;
  & > div {
    text-align: center;
  }
}

.intestazione {
  background-color: $primary;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.info {
  font-size: 15px;
  .header {
    padding: 15px;
    background-color: $accent;
    font-weight: bold;
  }
  .body {
    padding: 15px;
  }
}

.video .header {
  padding: 15px;
  background-color: $accent;
  font-weight: bold;
}
</style>