<template>
  <div id="eventiComponent">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-row>
          <b-col>
            <b-skeleton></b-skeleton>
          </b-col>
          <b-col>
            <b-skeleton></b-skeleton>
          </b-col>
          <b-col>
            <b-skeleton></b-skeleton>
          </b-col>
          <b-col>
            <b-skeleton></b-skeleton>
          </b-col>
          <b-col>
            <b-skeleton></b-skeleton>
          </b-col>
        </b-row>
        <b-skeleton-table
          hide-header
          :rows="5"
          :columns="1"
          :table-props="{ bordered: true, striped: false }"
        ></b-skeleton-table>
        <!--<div v-for="i in 10" :key="i">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>-->
      </template>
      <div v-if="!_.isEmpty(manifestazioni)">
        <b-nav tabs fill>
          <b-nav-item
            v-for="stato in stati"
            :key="stato"
            :class="[
              stato,
              classSelectedStato(stato),
              isMobile ? 'w-50' : 'w-25',
            ]"
            @click="selectStato(stato)"
          >
            {{ $t("evento.stato." + stato) }}
          </b-nav-item>
        </b-nav>
        <div
          v-for="manifestazione in manifestazioni"
          :key="manifestazione.ManifestazioneID"
        >
          <div
            v-if="showManifestazione(manifestazione.ManifestazioneID)"
            v-b-toggle="'manifestazione-' + manifestazione.ManifestazioneID"
            class="header-manifestazione"
            :class="
              statusManifestazione(
                mappedEventi[manifestazione.ManifestazioneID]
              )
            "
          >
            <b-row :class="isMobile ? 'no-gutters' : ''">
              <b-col cols="2" md="1">
                {{ manifestazione.DisciplineName }}
              </b-col>
              <b-col
                cols="8"
                md="1"
                :class="isMobile ? 'justify-content-center' : ''"
              >
                {{
                  mappedEventi[manifestazione.ManifestazioneID][0].StartDT
                    | moment("H:mm")
                }}
              </b-col>
              <b-col cols="2" class="d-block d-md-none text-center">
                <img
                  class="flag"
                  style="float: right"
                  :src="getFlagImageUrl(manifestazione.CategoryName)"
                />
              </b-col>
              <b-col cols="10" md="8">
                <b>{{ manifestazione.TournamentName }}</b>
              </b-col>
              <b-col cols="3" md="1" class="d-none d-md-block">
                <img
                  class="flag"
                  style="float: right"
                  :src="getFlagImageUrl(manifestazione.CategoryName)"
                />
              </b-col>
              <b-col
                cols="2"
                md="1"
                class="ranking"
                v-b-toggle="
                  'ranking-manifestazione-' + manifestazione.ManifestazioneID
                "
                @click.stop
              >
                <img
                  v-if="
                    statusManifestazione(
                      mappedEventi[manifestazione.ManifestazioneID]
                    ) == 'finished'
                  "
                  src="@/assets/ranking.png"
                />

                <img
                  v-else-if="
                    statusManifestazione(
                      mappedEventi[manifestazione.ManifestazioneID]
                    ) == 'live'
                  "
                  src="@/assets/rankinprogress.png"
                />
              </b-col>
            </b-row>
          </div>
          <b-collapse
            v-model="
              collapses[
                'ranking-manifestazione-' + manifestazione.ManifestazioneID
              ]
            "
            v-if="
              showManifestazione(manifestazione.ManifestazioneID) &&
              (statusManifestazione(
                mappedEventi[manifestazione.ManifestazioneID]
              ) == 'finished' ||
                statusManifestazione(
                  mappedEventi[manifestazione.ManifestazioneID]
                ) == 'live') &&
              classifiche[manifestazione.ManifestazioneID]
            "
            :id="'ranking-manifestazione-' + manifestazione.ManifestazioneID"
            class="ranking"
          >
            <b-table
              responsive
              large
              striped
              class="m-0"
              :items="classifiche[manifestazione.ManifestazioneID]"
              :fields="campiTabellaClassifica"
            ></b-table>
          </b-collapse>
          <b-collapse
            v-if="showManifestazione(manifestazione.ManifestazioneID)"
            @show="triggerBtnRanking(manifestazione.ManifestazioneID)"
            :id="'manifestazione-' + manifestazione.ManifestazioneID"
            :visible="
              toggleVisibilityCollapseManifestazione(
                manifestazione.ManifestazioneID
              )
            "
          >
            <b-list-group-item
              v-for="evento in mappedEventi[manifestazione.ManifestazioneID]"
              :key="evento.EventID"
              @click="openEvento(evento)"
            >
              <b-row class="no-gutters w-100">
                <b-col cols="4" md="1" :class="isMobile ? 'text-center' : ''">
                  {{ evento.StartDT | moment("H:mm") }}
                </b-col>
                <b-col cols="4" class="d-block d-md-none"> </b-col>
                <b-col
                  cols="4"
                  class="text-center stato-evento d-block d-md-none"
                  :class="evento.status"
                >
                  {{ $t("evento.stato." + evento.status) }}
                </b-col>
                <b-col cols="5" md="4" class="text-right">
                  {{ evento.team1 }}
                  <br v-if="isMobile" />
                  (<b>{{ evento.nick1 }}</b
                  >)
                </b-col>
                <b-col cols="2" class="text-center">
                  <b>
                    {{ evento.total_score_1 }} -
                    {{ evento.total_score_2 }}
                  </b>
                </b-col>
                <b-col cols="5" md="4" class="text-left">
                  {{ evento.team2 }}
                  <br v-if="isMobile" />
                  (<b>{{ evento.nick2 }}</b
                  >)
                </b-col>
                <b-col
                  cols="12"
                  md="1"
                  class="text-center stato-evento d-none d-md-block"
                  :class="evento.status"
                >
                  {{ $t("evento.stato." + evento.status) }}
                </b-col>
                <b-col
                  cols="12"
                  class="d-block d-md-none text-center"
                  style="text-decoration: underline"
                >
                  <span v-if="evento.status == 'InPlay'">Segui match</span>
                  <span v-else-if="evento.status == 'Finished'"
                    >Dettagli match</span
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-collapse>
        </div>
      </div>
      <div v-else>
        {{ $tc("evento.contatore", manifestazioni) }}
      </div>
    </b-skeleton-wrapper>

    <!--<div class="text-right">
      {{ $tc("evento.contatore", Object.keys(filteredEventi).length) }}
    </div>-->
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data: () => {
    return {
      //overlay: false,
      loading: true,
      loadingTime: 0,
      maxLoadingTime: 3,
      interval: null,
      eventi: {},
      mappedEventi: {},
      selectedStato: null,
      stati: ["All", "Scheduled", "InPlay", "Finished"],
      collapses: {},
      campiTabellaClassifica: [
        { key: "teamName", label: "Team Name" },
        { key: "nickName", label: "Nick Name" },
        {
          key: "vinte",
          label: "Vinte",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "pareggiate",
          label: "Pareggiate",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "perse",
          label: "Perse",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "puntifatti",
          label: "Fatti",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "puntisubiti",
          label: "Subiti",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "differenzapunti",
          label: "Differenza",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "punticlassifica",
          label: "Punti",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  props: [
    "isMobile",
    "manifestazioni",
    "classifiche",
    "selectedDisciplina",
    "selectedGiorno",
  ],
  created() {
    this.$_loadingTimeInterval = null;
  },
  mounted() {
    this.startLoading();
    this.getEventi();
    this.interval = setInterval(() => this.getEventi(), 15 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  watch: {
    selectedGiorno() {
      this.getEventi();
    },
    selectedDisciplina: function () {
      this.filterEventi();
    },
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    collapseMapsVisible(manifestazioneId) {
      if (
        this.statusManifestazione(this.mappedEventi[manifestazioneId]) ==
        "finished"
      ) {
        let index = "ranking-manifestazione-" + manifestazioneId;
        if (this._.isUndefined(this.collapses[index])) {
          this.$set(this.collapses, index, false);
          return index;
        }
        return index;
      }
      return false;
    },

    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    startLoading() {
      this.loading = true;
      this.loadingTime = 0;
    },
    toggleVisibilityCollapseManifestazione(idManifestazione) {
      var eventi = this.mappedEventi[idManifestazione];
      var now = this.$moment();
      var nowPlus = this.$moment().add(1, "h");
      var found = this._.find(eventi, (evento) => {
        var eventoStart = this.$moment(evento.StartDT);
        if (evento.status == "InPlay") return true;
        return eventoStart >= now && eventoStart <= nowPlus;
      });
      return found ? true : false;
    },
    getEventi(idManifestazione) {
      api.getEventi(idManifestazione, this.selectedGiorno, (data) => {
        this.eventi = data;
        this.filterEventi();
        this.loading = false;
      });
    },
    filterEventi() {
      var filteredEventi = this.eventi;
      if (this.selectedStato == null) this.selectedStato = "Scheduled";

      if (this.selectedStato != "All" && this.selectedStato != "Scheduled") {
        filteredEventi = this._.filter(filteredEventi, {
          status: this.selectedStato,
        });
      }
      this.mapEventiByManifestazione(filteredEventi);
      this._.forEach(this.manifestazioni, (manifestazione) => {
        this.collapseMapsVisible(manifestazione.ManifestazioneID);
      });
    },

    mapEventiByManifestazione(filteredEventi) {
      this.mappedEventi = this._.groupBy(filteredEventi, "ManifestazioneID");
    },
    openEvento(evento) {
      if (evento.status == "Scheduled") return false;
      let routeData = this.$router.resolve({
        name: "evento",
        params: {
          idEvento: evento.EventID,
          idManifestazione: evento.ManifestazioneID,
        },
      });
      window.open(
        routeData.href,
        "",
        "width=500,height=600,toolbar=0,menubar=0,location=0"
      );
      //this.$router.push({ name: 'evento', params: { idEvento: evento.EventID, idManifestazione: evento.ManifestazioneID } })
    },
    selectStato(stato) {
      this.selectedStato = stato;
      this.filterEventi();
    },
    classSelectedStato(stato) {
      if (stato == this.selectedStato) return "active";
      return "";
    },
    statusManifestazione(eventi) {
      if (
        this._.find(eventi, function (evento) {
          return evento.status == "InPlay";
        })
      )
        return "live";
      else if (
        this._.find(eventi, function (evento) {
          return evento.status == "Scheduled";
        })
      )
        return "scheduled";
      else return "finished";
    },
    getFlagImageUrl(nazione) {
      var images = require.context("@/assets/bandiere/", false, /\.png$/);
      let flagImage = "";
      try {
        flagImage = images(`./${nazione}.png`);
      } catch (error) {
        flagImage = images(`./Earth.png`);
      }
      return flagImage;
    },
    showManifestazione(idManifestazione) {
      if (this.mappedEventi[idManifestazione]) {
        if (this.selectedStato == "Scheduled")
          return (
            this.statusManifestazione(this.mappedEventi[idManifestazione]) !=
            "finished"
          );
        else return true;
      }
      return false;
    },
    triggerBtnRanking(idManifestazione) {
      if (
        this._.isUndefined(
          this.collapses["ranking-manifestazione-" + idManifestazione]
        ) ||
        this.statusManifestazione(this.mappedEventi[idManifestazione]) !=
          "finished"
      )
        return false;

      this.collapses["ranking-manifestazione-" + idManifestazione] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

#eventiComponent {
  border-radius: 0 !important;
  border: 4px solid gray;
  .nav.nav-tabs {
    //background-color: $accent;
    border-bottom: 2px solid gray;

    text-transform: uppercase;
    .nav-item {
      border: 2px solid gray;
      border-top: 0;
      color: $dark-primary-text;
      font-weight: bold;

      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }

      &.active {
        filter: brightness(80%);
      }

      &.All {
        background-color: $light-text;
      }
      &.Scheduled {
        background-color: $warn;
      }
      &.InPlay {
        background-color: $accent;
      }
      &.Finished {
        background-color: $primary;
        a {
          color: $light-text;
        }
      }
      .nav-link {
        &:focus,
        &:hover {
          border: inherit;
          border-radius: 0;
          filter: brightness(80%);
        }
      }
    }
  }

  .header-manifestazione {
    border-radius: 0;
    border-top: 1px solid gray;
    .row {
      width: 96%;
      margin: 0 2%;
      & > div {
        padding: 15px;
        display: flex;
        align-items: center;
        &.ranking {
          justify-content: center;
          background-color: $warn;
          padding: 6px 10px;
          margin-top: 6px;
          img {
            height: 30px;
          }
        }
      }

      @media (max-width: 760px) {
        & > div {
          padding: 8px;
        }
      }
    }
    &.scheduled {
      background-color: $warn;
    }
    &.live {
      background-color: $accent;
    }
    &.finished {
      background-color: $primary-opacity-80;
      color: $light-text;
    }
  }
  .list-group-item {
    display: flex;
    justify-content: space-between;
    &:nth-child(even) {
      background-color: whitesmoke;
    }
    .stato-evento {
      &.InPlay {
        background-color: $accent;
      }
      &.Finished {
        font-weight: bold;
      }
    }
  }
  .collapse.ranking {
    border: 4px solid $warn;
  }
  img.flag {
    height: 20px;
    border-radius: 25%;
  }
}
</style>