<template>
  <div id="manifestazioniComponent" class="row my-4">
    <div class="col-md-3">
      <Giorno @select="selectGiorno($event)"></Giorno>

      <Menu
        subject="discipline"
        :loading="loading"
        :title="$t('disciplina.tutto')"
        :items="discipline"
        :selectedItem="selectedDisciplina"
        @select="selectDisciplina($event)"
      ></Menu>

      <Menu
        subject="nazioni"
        :loading="loading"
        :title="$t('nazione.tutto')"
        :items="nazioni"
        :selectedItem="selectedNazione"
        @select="selectNazione($event)"
        @getItemImage="getMenuFlagImageUrl($event)"
      ></Menu>

      <Funfact></Funfact>
    </div>

    <div class="col-md-9">
      <Eventi
        ref="eventiComponent"
        :isMobile="isMobile"
        :manifestazioni="filteredManifestazioni"
        :classifiche="classifiche"
        :selectedGiorno="selectedGiorno"
        :selectedManifestazione="selectedManifestazione"
        :selectedDisciplina="selectedDisciplina"
      ></Eventi>
    </div>
  </div>
</template>


<script>
import Menu from "@/components/manifestazioni/Menu.vue";
import Eventi from "@/components/manifestazioni/Eventi.vue";
import Giorno from "@/components/manifestazioni/Giorno.vue";
import Funfact from "@/components/manifestazioni/Funfact.vue";

import api from "../services/api";

export default {
  data: () => {
    return {
      isMobile: false,
      loading: false,
      selectedGiorno: null,
      discipline: {},
      selectedDisciplina: null,
      nazioni: {},
      selectedNazione: null,
      manifestazioni: {},
      filteredManifestazioni: {},
      selectedManifestazione: null,
      classifiche: [],
      interval: null,
    };
  },
  components: {
    Menu,
    Eventi,
    Giorno,
    Funfact,
  },

  mounted() {
    const now = new Date();
    const today =
      now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
    this.selectGiorno(today);
    //this.getListaManifestazioni();
    this.interval = setInterval(
      () => this.selectGiorno(this.selectedGiorno),
      60 * 60 * 1000
    );
    this.isMobile = window.innerWidth <= 760;
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    selectGiorno(data) {
      this.selectedGiorno = data;
      this.getListaManifestazioni();
      this.getClassifiche();
    },
    selectDisciplina(disciplina) {
      this.selectedDisciplina = disciplina;
      this.filterManifestazioni();
    },
    selectManifestazione(idManifestazione) {
      this.selectedManifestazione = idManifestazione;
    },
    selectNazione(idNazione) {
      this.selectedNazione = idNazione;
      this.filterManifestazioni();
    },
    getListaManifestazioni() {
      this.loading = true;
      api.getManifestazioni(this.selectedGiorno, (data) => {
        this.manifestazioni = data;
        this.discipline = this._.chain(this.manifestazioni)
          .map("DisciplineName")
          .uniq()
          .value();
        this.nazioni = this._.chain(this.manifestazioni)
          .map("CategoryName")
          .uniq()
          .sortBy()
          .value();
        this.filterManifestazioni();
        this.loading = false;
      });
    },
    getClassifiche() {
      api.getClassifiche(this.selectedGiorno, (data) => {
        this.classifiche = data;
      });
    },
    filterManifestazioni() {
      let disciplina = this.selectedDisciplina;
      let nazione = this.selectedNazione;
      let filteredManifestazioni = this.manifestazioni;

      if (disciplina !== null)
        filteredManifestazioni = this._.filter(filteredManifestazioni, {
          DisciplineName: disciplina,
        });

      if (nazione !== null)
        filteredManifestazioni = this._.filter(filteredManifestazioni, {
          CategoryName: nazione,
        });

      this.filteredManifestazioni = filteredManifestazioni;
    },
  },
};
</script>


<style lang="scss">
#manifestazioniComponent {
  min-height: 80vh;
  font-size: 12px;
}

@media (max-width: 760px) {
  #manifestazioniComponent {
    min-height: 40vh;
  }
}
</style>
