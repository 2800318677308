import Vue from 'vue';

export default {
    sendGet: (endpoint, data = {}) => {
        return Vue.axios.get(endpoint, {
            params: data,
        }).catch((error) => {
            console.log(error.response.data.message);
            return false;
        });
    },
    sendPost: (endpoint, data = {}) => {
        return Vue.axios.post(endpoint, data).catch((error) => {
            console.log(error.response.data.message);
            return false;
        });
    },
}